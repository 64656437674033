.player-made-choices {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  // display: flex;
  // flex-flow: column nowrap;
  // justify-content: space-evenly;
  // align-items: center;

  font: font-barlow(4.5rem);
  color: white;

  &--correct,
  &--not-correct {
    height: 100%;
    border-radius: calc(var(--borderRadius) * 2);
  }

  &--correct {
    background: var(--generalAccentColor);
  }
  &--not-correct {
    background: $color-red-torch-red;
  }

  &__choice {
    position: relative;
    margin: 3rem 1rem;
    text-indent: 400%;
    white-space: nowrap;
    color: transparent;

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      width: 8.5rem;
      height: 10rem;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 8.5rem auto;
    }

    @each $choice-index, $choice-settings in $choice-settings-map {
      &--index--#{$choice-index}:before {
        background-image: map-get($choice-settings, "image");
      }
    }
  }

  &__message {
    margin: 2rem 1rem;
    font: font-barlow(xx-large);
    text-align: center;
  }

  &__points {
    display: inline-block;
    padding: .1rem 1rem;
    margin: 1rem;
    font-size: 2rem;
    line-height: 1em;
    background: white;
    border-radius: calc(var(--borderRadius) * 2);
    &:before {
      content: "+";
    }
  }
  &--correct &__points {
    color: var(--generalAccentColor);
  }
  &--not-correct &__points {
    color: $color-red-cedar;
  }


  .hint-toast__body & {
    position: static;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    width: auto;
    height: auto;

    background: transparent;
    &__choice {
      margin: 0;
      height: 2em;
      text-indent: 0;
      white-space: normal;
      line-height: 1.3em;
      overflow: hidden;
    }
    &__message {
      margin: 0;
    }
  }
}
