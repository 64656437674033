$font-path-prefix: "../fonts";
$font-line-height-default: 1.6em;
$font-weight-default: normal;



@mixin improve-font-rendering() {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


@function font($size, $weight, $line-height, $font-family) {
  @return #{$weight} #{$size} / #{$line-height} $font-family;
}

$font-family-barlow: "Barlow", sans-serif;
@function font-barlow($size, $weight: normal, $line-height: $font-line-height-default) {
  @return font($size, $weight, $line-height, $font-family-barlow);
}

$font-family-barlow-condensed: "BarlowCondensed", sans-serif;
@function font-barlow-condensed($size, $weight: 500, $line-height: $font-line-height-default) {
  @return font($size, $weight, $line-height, $font-family-barlow-condensed);
}

$font-family-economica: "Economica", sans-serif;
@function font-economica($size, $weight: normal, $line-height: $font-line-height-default) {
  @return font($size, $weight, $line-height, $font-family-economica);
}


@font-face {
    font-family: "Barlow Condensed";
    src: url("#{$font-path-prefix}/BarlowCondensed-Medium.eot");
    src: url("#{$font-path-prefix}/BarlowCondensed-Medium.eot?#iefix") format("embedded-opentype"),
        url("#{$font-path-prefix}/BarlowCondensed-Medium.woff2") format("woff2"),
        url("#{$font-path-prefix}/BarlowCondensed-Medium.woff") format("woff"),
        url("#{$font-path-prefix}/BarlowCondensed-Medium.ttf") format("truetype"),
        url("#{$font-path-prefix}/BarlowCondensed-Medium.svg#BarlowCondensed-Medium") format("svg");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Economica";
    src: url("#{$font-path-prefix}/Economica-Regular.eot");
    src: url("#{$font-path-prefix}/Economica-Regular.eot?#iefix") format("embedded-opentype"),
        url("#{$font-path-prefix}/Economica-Regular.woff2") format("woff2"),
        url("#{$font-path-prefix}/Economica-Regular.woff") format("woff"),
        url("#{$font-path-prefix}/Economica-Regular.ttf") format("truetype"),
        url("#{$font-path-prefix}/Economica-Regular.svg#Economica-Regular") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Economica";
    src: url("#{$font-path-prefix}/Economica-Bold.eot");
    src: url("#{$font-path-prefix}/Economica-Bold.eot?#iefix") format("embedded-opentype"),
        url("#{$font-path-prefix}/Economica-Bold.woff2") format("woff2"),
        url("#{$font-path-prefix}/Economica-Bold.woff") format("woff"),
        url("#{$font-path-prefix}/Economica-Bold.ttf") format("truetype"),
        url("#{$font-path-prefix}/Economica-Bold.svg#Economica-Bold") format("svg");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "Economica";
    src: url("#{$font-path-prefix}/Economica-BoldItalic.eot");
    src: url("#{$font-path-prefix}/Economica-BoldItalic.eot?#iefix") format("embedded-opentype"),
        url("#{$font-path-prefix}/Economica-BoldItalic.woff2") format("woff2"),
        url("#{$font-path-prefix}/Economica-BoldItalic.woff") format("woff"),
        url("#{$font-path-prefix}/Economica-BoldItalic.ttf") format("truetype"),
        url("#{$font-path-prefix}/Economica-BoldItalic.svg#Economica-BoldItalic") format("svg");
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: "Barlow";
    src: url("#{$font-path-prefix}/Barlow-Light.eot");
    src: url("#{$font-path-prefix}/Barlow-Light.eot?#iefix") format("embedded-opentype"),
        url("#{$font-path-prefix}/Barlow-Light.woff2") format("woff2"),
        url("#{$font-path-prefix}/Barlow-Light.woff") format("woff"),
        url("#{$font-path-prefix}/Barlow-Light.ttf") format("truetype"),
        url("#{$font-path-prefix}/Barlow-Light.svg#Barlow-Light") format("svg");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Barlow";
    src: url("#{$font-path-prefix}/Barlow-Medium.eot");
    src: url("#{$font-path-prefix}/Barlow-Medium.eot?#iefix") format("embedded-opentype"),
        url("#{$font-path-prefix}/Barlow-Medium.woff2") format("woff2"),
        url("#{$font-path-prefix}/Barlow-Medium.woff") format("woff"),
        url("#{$font-path-prefix}/Barlow-Medium.ttf") format("truetype"),
        url("#{$font-path-prefix}/Barlow-Medium.svg#Barlow-Medium") format("svg");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Economica";
    src: url("#{$font-path-prefix}/Economica-Italic.eot");
    src: url("#{$font-path-prefix}/Economica-Italic.eot?#iefix") format("embedded-opentype"),
        url("#{$font-path-prefix}/Economica-Italic.woff2") format("woff2"),
        url("#{$font-path-prefix}/Economica-Italic.woff") format("woff"),
        url("#{$font-path-prefix}/Economica-Italic.ttf") format("truetype"),
        url("#{$font-path-prefix}/Economica-Italic.svg#Economica-Italic") format("svg");
    font-weight: normal;
    font-style: italic;
}

