html {
  font-size: 62.5%;
}

@media screen and (max-height: 400px) {
  html {
    font-size: 50%;
  }
}

@media screen and (min-width: 1000px) {
  html {
    font-size: 80%;
  }
}

body {
  @include improve-font-rendering();
  font: font-barlow(2rem);
}

input {
  font: font-barlow(2rem);
}

body {
  background-color: white;
}

p {
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--fontFamily);
  font-weight: normal;
  font-size: revert;
  color: $color-blue-blue-stone;
}

pre {
  font-size: 1rem;
  line-height: 1em;
}
