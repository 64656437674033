.screen-page {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  flex-flow: column nowrap;

  &__header {
    height: 3.8rem;
    background: var(--headerBgColor) var(--myLogo) center center / auto 2rem no-repeat;
    display: flex;
  }

  &__pin,
  &__countdown {
    color: white;
    margin-top: .2rem;
    margin-left: 1rem;
  }
  &__countdown {
    margin-left: 3rem;
  }

  &__content {
    flex: 1 1;
  }

  &--display-event &__content {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
  }

  & .livestream-player {
    position: fixed;
    top: 5rem;
    right: 1rem;
    width: 30vw;
    max-width: 400px;
    height: auto;
    border-radius: var(--borderRadius);
    overflow: hidden;
    transition: right .5s ease, opacity .3s ease;
  }
  &--not-display-event .livestream-player {
    right: 1rem;
    opacity: 1;
  }
  // Always show player for now
  //&--display-event .livestream-player {
  //  opacity: 0;
  //  right: -100vw;
  //}
}
