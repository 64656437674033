@import "../media/fonts/fonts";
@function font-default($size, $weight: normal, $line-height: $font-line-height-default) {
  @return font($size, $weight, $line-height, var(--fontFamily));
}
@import "settings";

// tools
@import "tools.text";
@import "tools.maps";

// elements
@import "~react-html5video/dist/styles.css";
@import "~video-react/styles/scss/video-react";
@import "~rodal/lib/rodal.css";

$font-size-base: 2rem;
$font-family-sans-serif: $font-family-barlow;
$font-weight-base: $font-weight-default;
$font-height-base: $font-line-height-default;
$border-radius: 0;
@import "~bootstrap/scss/bootstrap";
.btn svg { vertical-align: baseline; }
.modal, .modal-backdrop { z-index: 1040; }
.dropdown-menu.show {
	height: initial;
  box-shadow: 5px 5px 15px -5px rgba(0, 0, 0, 0.3);
}

// generic
@import "generic.normalize";
@import "generic.box-sizing";
@import "generic.setup";
@import "generic.forms";

// components
@import "component.app";
@import "component.app-reconnecting";
@import "component.login-page";

@import "component.toastify";
@import "component.modal";

@import "component.player-info";
@import "component.player-meta";
@import "component.player-content";
@import "component.player-buttons";
@import "component.player-made-choices";

@import "component.screen-page";
@import "component.screen-waiting-for-game";
@import "component.screen-intro";
@import "component.screen-question";
@import "component.screen-leaderboard";
@import "component.livestream-player";

@import "component.screen-as-player";
@import "component.team-chooser-page";
@import "component.leaderboard";
@import "component.leaderboard-table";

@import "component.master-content";
@import "component.stream-page";

