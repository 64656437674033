.app {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  font-size: 1.5rem;
  color: $color-blue-big-stone;
  background: var(--appBackground);
  background-repeat: no-repeat;
  background-size: cover;
}
