button[type=submit] {
  padding: .5rem 1.5rem;
  font: inherit;
  color: white;
  background: $color-green-blue-stone;
  border: none;
  border-radius: var(--borderRadius);
  transition: background .3s ease;

  &[disabled] {
    opacity: .7;
    background: gray;
  }
}

.form-item {
  margin: 1rem 0;
  .form-item__label {
    transition: color .3s ease;
  }
  input {
    display: block;
    width: 100%;
    margin: .5rem 0;
    padding: .6rem;
    font: font-barlow(1.2rem, 300);
    background: white;
    border: .1rem solid $color-gray-oslo-gray;
    border-radius: var(--borderRadius);
    transition: background .3s ease, border .3s ease;
  }
}

.form-item--state--error {
  .form-item__label {
    color: $color-red-el-salva;
  }
  input {
    border-color: $color-red-el-salva;
  }
}

.form-item__message {
  padding: .5rem;
  font: font-barlow(1.8rem, 300);
  border-radius: var(--borderRadius);

  &--error {
    color: $color-red-el-salva;
    background: fade-out($color-red-el-salva, .9);
  }
}

.form-error {
  padding: .5rem;
  font: font-barlow(1.8rem, 300);
  color: $color-red-el-salva;
  background: fade-out($color-red-el-salva, .9);
  border-radius: var(--borderRadius);
}


/* ------------------------------------------------------------Ready Switch styles ------------------------------------------------------------*/
.custom-control-input:checked~.custom-control-label:before {
	border-color: var(--generalAccentColor);
	background-color: var(--generalAccentColor);
}

.custom-control-label:after, .custom-control-label:before {
	top: .5rem;
}
.custom-switch .custom-control-label:after {
	top: calc(.5rem + 2px);
}
.custom-switch .custom-control-label:before,
.custom-switch .custom-control-label:after {
	margin-top:12%
}


