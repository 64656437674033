.player-buttons {
  flex: 1 1 auto;
  height: 100%;
}
.player-buttons__content {
  padding-bottom: 3rem;
}
.player-buttons__button {
  display: flex;
  width: 100%;
  min-height: 6rem;
  background: #aaa;
  border: none;
  border-radius: calc(var(--borderRadius) * 2);
  transition: all .3s ease;
  margin-bottom: 1rem;
  &:last-child {
    margin-bottom: 0;
  }
  span {
    display: flex;
    align-self: center;
    padding: .5rem 1rem .6rem .5rem;
    text-align: left;
    color: white;
  }
  &:before {
    content: "";
    display: flex;
    //flex: 1 0 auto;
    width: 6rem;
    height: 6rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 4rem;
  }
}
.player-buttons--made-choice .player-buttons__content .player-buttons__button--not-chosen {
  opacity: .3;
  transform: scale(.8, .8);
}

.player-buttons--made-choice .player-buttons__content .player-buttons__button--chosen {
  transform: rotate(-1deg);
}

.player-buttons--count--1.player-buttons--not-has-choice-with-short-answer,
.player-buttons--count--2.player-buttons--not-has-choice-with-short-answer,
.player-buttons--count--3.player-buttons--not-has-choice-with-short-answer,
.player-buttons--count--4.player-buttons--not-has-choice-with-short-answer,
.player-buttons--count--5.player-buttons--not-has-choice-with-short-answer,
.player-buttons--count--6.player-buttons--not-has-choice-with-short-answer {
  /* resetting some styles from choice list */
  flex: 0 1 auto;
  height: auto;
  .player-buttons__content {
    padding-bottom: 0;
  }
  /* /resetting style from choice list */

  .player-buttons__content {
    display: grid;
    grid-gap: 1rem;

    grid-template-columns: 12rem 12rem;
    grid-auto-columns: 12rem;

    grid-template-rows: 12rem 12rem;
    grid-auto-rows: 12rem;
    .player-buttons__button {
      position: relative;
      overflow: hidden;
      margin-bottom: 0;
      text-indent: 200%;
      white-space: nowrap;
      color: transparent;

      span {
        display: none;
      }

      &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        width: 10rem;
        height: 10rem;
        transform: translate(-50%, -50%);
        background-position: center;
        background-repeat: no-repeat;
      }

      @each $choice-index, $choice-settings in $choice-settings-map {
        &--index--#{$choice-index}:before {
          background-size: 8.5rem auto;
        }
      }
    }

    &.player-buttons--made-choice .player-buttons__content .player-buttons__button--not-chosen {
      opacity: .3;
      transform: scale(.8, .8);
    }

    &.player-buttons--made-choice .player-buttons__content .player-buttons__button--chosen {
      transform: rotate(-2deg);
    }
  }
}

@each $choice-index, $choice-settings in $choice-settings-map {
  .player-buttons__button--index--#{$choice-index} {
    background: map-get($choice-settings, "color");
    &:before {
      background-image: map-get($choice-settings, "image");
    }
  }
}
