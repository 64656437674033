.screen-waiting-for-game {
  padding: 1rem 2rem;
  text-align: center;

  &__header {
    margin: 5rem 0;
  }
  &__pin {
    display: block;
    margin: 2rem auto;
    padding: .6rem;
    max-width: 40rem;
    width: 100%;
    border: 1px solid $color-blue-big-stone;
    border-radius: calc(var(--borderRadius) * 2);
  }
  &__presence {
    margin: 5rem 0;
  }
  &__separator {
    display: inline-block;
    margin-left: 1rem;
    margin-right: 1rem;
    overflow: hidden;
    font-weight: 300;
  }
  &__nickname {
    display: inline-block;
    max-width: 20rem;
    @include overflow-ellipsis();
  }
}

