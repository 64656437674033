$color-gray-white-smoke: #f4f4f4;
$color-gray-blue-charcoal: #212529;
$color-gray-gallery: #eee;
$color-gray-oslo-gray: #858C94;

$color-blue-big-stone: #172e3d;
$color-blue-blue-stone: #00575F;

$color-blue-picton-blue: #27C0EB;
$color-blue-picton-blue-lighter: #52CDEF;

$color-red-el-salva: #882f36;

$color-red-cedar: #3c1518;
$color-red-kabul: #634446;

$color-red-red-ribbon: #ea004d;
$color-red-amaranth: #ee3371;

$color-red-vivid-violet: #8B39A6;
$color-red-wisteria: #A261B8;
$color-red-torch-red: #FF004D;

$color-green-persian-green: #00b592;
$color-green-shamrock: #33c4a8;

$color-green-christi: #87A70D;

$color-green-blue-stone: #00575f;

$color-yellow-tangerine: #f18f01;
$color-yellow-sea-buckthorn: #f4a534;



:root {
  --myLogo: url(../media/gfx/logo.png);
  --fontFamily: #{$font-family-barlow};

  --generalAccentColor: #{$color-green-persian-green};
  --appBackground: #{$color-gray-gallery};

  --answerColor1: #{$color-green-persian-green};
  --answerColor1a: #{$color-green-shamrock};
  --answerIcon1: url("../media/gfx/icon-choice-a.png");

  --answerColor2: #{$color-yellow-tangerine};
  --answerColor2a: #{$color-yellow-sea-buckthorn};
  --answerIcon2: url("../media/gfx/icon-choice-b.png");

  --answerColor3: #{$color-red-red-ribbon};
  --answerColor3a: #{$color-red-amaranth};
  --answerIcon3: url("../media/gfx/icon-choice-c.png");

  --answerColor4: #{$color-red-cedar};
  --answerColor4a: #{$color-red-kabul};
  --answerIcon4: url("../media/gfx/icon-choice-d.png");

  --answerColor5: #{$color-blue-picton-blue};
  --answerColor5a: #{$color-blue-picton-blue-lighter};
  --answerIcon5: url("../media/gfx/icon-choice-e.png");

  --answerColor6: #{$color-red-vivid-violet};
  --answerColor6a: #{$color-red-wisteria};
  --answerIcon6: url("../media/gfx/icon-choice-f.png");

  --teamColor: #{$color-yellow-tangerine};
  --borderRadius: .5rem;
  --headerBgColor: hsla(0, 0%, 100%, .2);

  --contentContainerBgColor: hsla(0, 0%, 100%, .2);
  --blurContentAndHeader: blur(5px);
}



$choice-settings-map: (
  0: (
    color: var(--answerColor1),
    color2: var(--answerColor1a),
    image: var(--answerIcon1),
  ),
  1: (
    color: var(--answerColor2),
    color2: var(--answerColor2a),
    image: var(--answerIcon2),
  ),
  2: (
    color: var(--answerColor3),
    color2: var(--answerColor3a),
    image: var(--answerIcon3),
  ),
  3: (
    color: var(--answerColor4),
    color2: var(--answerColor4a),
    image: var(--answerIcon4),
  ),
  4: (
    color: var(--answerColor5),
    color2: var(--answerColor5a),
    image: var(--answerIcon5),
  ),
  5: (
    color: var(--answerColor6),
    color2: var(--answerColor6a),
    image: var(--answerIcon6),
  ),
);
