.team-chooser-page {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 1vw;
  padding: 10vw;
  overflow: auto;
  background: transparent;
  border-radius: var(--borderRadius);
}

.team-chooser-page__choose {
  margin: 1vw auto;
  padding: 2rem;
  background: white;
}

.team-chooser-page__create {
  margin: 1vw auto;
  padding: 2rem;
  background: white;

  input[type=text] {
    font-weight: 700;
  }
}

.team-chooser-page__header {
  display: flex;

  justify-content: center;
  flex-flow: row nowrap;

  > * {
    padding: 1rem 3rem;
  }
  > * + * {
    position: relative;
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 1.5rem;
      bottom: 1.5rem;
      border-left: .2rem solid black;
    }
  }
}
.team-chooser-page__contents {
  margin: 3rem auto;
  max-width: 100rem;

  display: flex;
  flex-direction: column;

  > * {
    flex: 1 1 auto;
    padding: 1rem 3rem;
  }
}

@media screen and (min-width: 1000px) {
  .team-chooser-page__contents {
    flex-direction: row;
  }
}



.team-chooser-page-create-form,
.team-chooser-page-team-list {
  padding: 2rem;
  background: fade-out($color-gray-white-smoke, .1);
  border-radius: var(--borderRadius);
}

.team-chooser-page-create-form__submit {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.team-chooser-page-team-list__team {
  display: flex;
  justify-content: stretch;
  align-items: center;
  margin: 1rem 0;
  padding: 1.5rem 2rem 1.8rem 2rem;
	padding: 1rem;
  font: font-barlow(1.8rem, 500, 1rem);
	line-height: normal;
  color: white;
  background-color: var(--teamColor);
	border: none;
  border-radius: var(--borderRadius);
  cursor: pointer;
  //transition: all .3s ease;
  //transform: rotate(0) scale(1);
  //&:hover {
  //  transform: rotate(-.5deg) scale(1.05);
  //}

  &-name {
    flex: 1 1 auto;
    max-width: 20rem;
    color: white;
    background-color: var(--teamColor);
    border: .1rem solid var(--teamColor);
    @include overflow-ellipsis();
  }

  &-member-count {
    flex: 0 0 auto; 
    align-items: center;
    width: 5rem;
    text-align: right;
    &:after {
      content: "";
      display: inline-block;
      width: 1.5rem;
      height: 1.5rem;
      margin-left: .5rem;
      vertical-align: middle;
      background: transparent url(../media/gfx/icon-user.svg) center/contain no-repeat;
    }

    .team-info-container {
      display: inline-block;
      .dropdown-toggle {
        color: inherit;
        background: var(--generalAccentColor);
        border: none;
        border-radius: var(--borderRadius);
        &:focus {
          outline: 0;
          border: none;
          box-shadow: none;
        }
        &:after {
          display: none;
        }
      }
    }
  }
}

.team-chooser-page-create-form__submit button {
	padding: 10px;
	line-height: normal;
	color: white;
	background: var(--generalAccentColor);
	border: none;
	border-radius: var(--borderRadius);
}
