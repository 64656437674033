.screen-as-player-page {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  flex-flow: column nowrap;

  overflow-y: auto;

  &__header {
    position: fixed;
    z-index: 10;
    width: 100%;
    height: 4.5rem;
    display: flex;
    padding: .5rem;
    background: var(--headerBgColor);
    backdrop-filter: var(--blurContentAndHeader);

    div {
      height: 100%;
      margin: 0 .2rem;
    }

    &__pin,
    &__countdown {
      margin-top: .2rem;
      margin-left: 1rem;
    }
    > :first-child ~ * {
      margin-left: 0;
      border-radius: var(--borderRadius);
    }
    &__menu {
      .btn {
        margin-right: .1rem;
        padding: .2rem .5rem 0 .5rem;
        width: 3.5rem;
        height: 100%;
        color: white;
        background: var(--generalAccentColor);
        border: none;
        border-radius: var(--borderRadius);
        svg {
          width: 1.5rem;
          height: 100%;
        }
      }
    }
    &__countdown {
      min-width: 7rem;
      text-align: center;
    }
    &__team {
      min-width: 2rem;
      align-items: center;
      white-space: nowrap;
      .dropdown-toggle {
        width: 100%;
        max-width: 20vw;
        height: 100%;
        max-height: 100%;
        @include overflow-ellipsis();
        font-size: inherit;
        color: white;
        background-color: var(--teamColor);
        border: .1rem solid var(--teamColor);
        border-radius: var(--borderRadius);
      }
      color: white;
      svg {
        margin-top: -.5rem;
        margin-right: .5rem;
      }
      span {
        vertical-align: middle;
      }
    }
    &__score,
    &__metrics {
      color: white;
      margin-left: 1rem;
      display: flex;
      .metric {
        margin-left: .5rem;
      }
    }
    & .metric,
    &__is-ready {
      margin: 0 .5rem 0 .5rem;
      padding: .1rem;
      color: $color-blue-big-stone;
      max-width: 15rem;
      min-height: 100%;
      background-color: white;
      @include overflow-ellipsis();
    }
    & .metric {
      border-radius: var(--borderRadius);
    }
    &__logo {
      position: relative;
      flex: 1 0 10rem;
      height: 3.8rem;
      width: 10rem;
      padding-right: 1rem;
      &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 12rem;
        background: transparent var(--myLogo) center/10rem auto no-repeat;
      }
    }
  }


  &__content {
    flex: 1 1;
    margin: 6rem auto;
    padding: 1.5rem;
    max-width: 100rem;
    width: 100%;
    height: fit-content;
    overflow: visible;
    line-height: normal;
    text-align: left;
    background: var(--contentContainerBgColor);
    border-radius: var(--borderRadius);
    backdrop-filter: var(--blurContentAndHeader);
  }

  &--display-event &__content {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
  }

  & .livestream-player {
    position: fixed;
    top: 5rem;
    right: 1rem;
    width: 30vw;
    max-width: 400px;
    height: auto;
    border-radius: var(--borderRadius);
    overflow: hidden;
    transition: right .5s ease, opacity .3s ease;
  }
  &--not-display-event .livestream-player {
    right: 1rem;
    opacity: 1;
  }
  // Always show player for now
  //&--display-event .livestream-player {
  //  opacity: 0;
  //  right: -100vw;
  //}

  &--overflow-y-active &__overflow-indicator {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    transition: all .3s ease;
  }
  &--overflow-y-active#{&}--overflow-y-scroll-begin &__overflow-indicator,
  &--overflow-y-active#{&}--not-overflow-y-scroll-end#{&}--overflow-y-scroll-begin &__overflow-indicator {
    box-shadow: 0px 10px 20px 5px rgba(0,0,0,0.6);
  }
  &--overflow-y-active#{&}--not-overflow-y-scroll-end &__overflow-indicator {
    box-shadow: 0px 15px 20px 5px rgba(0,0,0,0.6);
  }
}

.metric {
  display: inline-flex;
  align-items: stretch;

  &__name {
    display: inline-block;
    width: 3rem;
    overflow: hidden;
    white-space: nowrap;
    user-select: none;
    color: transparent;
  }

  &__value,
  &__difference {
    display: flex;
    padding-left: .5rem;
    padding-right: .5rem;
    align-items: center;
  }

  $metrics: "score", "budget", "rep1", "rep2";
  @each $metric in $metrics {
    &--id--#{$metric} &__name {
      background: transparent url(../media/gfx/icon-metric-#{$metric}.png) center center / contain no-repeat;
    }
  }
}

.screen-as-player-waiting-for-game {
  padding: 1rem 2rem;
  text-align: center;

  &__header {
    margin: 5rem 0;
  }
  &__pin {
    display: block;
    margin: 2rem auto;
    padding: .6rem;
    max-width: 40rem;
    width: 100%;
    border: 1px solid $color-blue-big-stone;
    border-radius: calc(var(--borderRadius) * 2);
  }
  &__presence {
    margin: 5rem 0;
  }
  &__separator {
    display: inline-block;
    margin-left: 1rem;
    margin-right: 1rem;
    overflow: hidden;
    font-weight: 300;
  }
  &__nickname {
    display: inline-block;
    max-width: 20rem;
    @include overflow-ellipsis();
  }
  &__qr,
  &__pin,
  &__url {
    display: none;
  }
  &__scenario-name {
    margin: 2rem;
    padding: 1rem;
    font: font-default(3rem);
    font-weight: 900;
    text-transform: uppercase;
    word-wrap: break-word;
    color: white;
    background-color: var(--generalAccentColor);
    border: none;
    border-radius: var(--borderRadius);
  }
}


.screen-as-player-intro {
  &--content-type--video {
    flex-grow: 1;
    align-self: stretch;
    display: flex;
    position: relative;

    .rh5v-DefaultPlayer_component {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
}


.screen-as-player-question {
  width: 100%;
  min-width: initial;
  padding: 1rem;
  font-size: 1.5rem;

  &__countdown {
    position: relative;
    z-index: 10;
    margin: auto;
    padding: .5rem 2rem .5rem 2rem;
    width: fit-content;
    text-align: center;
    color: white;
    background-color: $color-blue-big-stone;
    border-radius: calc(var(--borderRadius) * 10);
  }

  &__content {
    margin: 1rem;
    font: font-default(2.3rem);
    font-size: larger;
    text-align: center;
    color: $color-blue-blue-stone;
  }

  &__choices {
    display: flow-root;
    width: fit-content;
    max-width: 100rem;
    margin: 0 auto;
    grid-gap: 7rem 1.5rem;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 1fr;
  }

  @media screen and (max-width: 700px) {
    &__choices {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @media screen and (max-width: 500px) {
    &__choices {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media screen and (max-width: 1000px) {
    max-width: 100%;
  }

  &__choice {
    position: relative;
    margin: 1rem;
    padding: 1.5rem 2rem 1.5rem 1.5rem;
    min-width: 0;
    min-height: 8.5rem;
    text-align: left;
    font-size: 1.5rem;
    line-height: 2rem;
    color: white;
    background: #aaa;
    border-radius: calc(var(--borderRadius) * 2);

    &:before {
      content: "";
      float: left;
      display: block;
      margin-right: 2rem;
      width: 100%;
      max-width: 5.5rem;
      height: 5.5rem;
      background-color: transparent;
      background-repeat: no-repeat;
      background-position: left;
      background-size: contain;
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
    }

    @each $choice-index, $choice-settings in $choice-settings-map {
      &--index--#{$choice-index} {
        background: map-get($choice-settings, "color");
        &:before {
          background-image: map-get($choice-settings, "image");
        }
      }
    }

    &__points {
      width: 100%;
      padding-top: .5rem;
      padding-left: 0;

      font-size: 1.5rem;
      color: white;

      background-color: transparent;
      background-repeat: no-repeat;
      background-position: right;
      background-size: 2.5rem;
      &__stats {
        display: inline-block;
        margin: 0 1rem;
        &:before {
          content: "";
          display: inline-block;
          width: 2rem;
          height: 2rem;
          margin-right: .5rem;
          background-repeat: no-repeat;
          background-position: center bottom;
          background-size: 2rem;
        }
      }
      &__received {
        display: inline-block;
        margin: 0 1rem;
        &:before {
          content: "+";
        }
      }
    }

    &--correct &__points {
      background-image: url(../media/gfx/icon-correct.svg);
      &__stats {
        color: white;
        &:before {
          background-image: url(../media/gfx/icon-correct-person.svg);
        }
      }
    }
    &--not-correct &__points {
      background-image: url(../media/gfx/icon-not-correct.svg);
      &__stats:before {
        background-image: url(../media/gfx/icon-not-correct-person.svg);
      }
    }
  }
}

.screen-as-player-question {
  .screen-as-player-page--is-team-leader &__choice {
    cursor: pointer;
  }
  &__content audio {
    width: 100%;
  }
  &__choice {
    transition: all .3s ease;
  }
  &--made-choice &__choice--chosen {
    transform: rotate(-1deg) scale(1.1);
  }
  &--made-choice &__choice--not-chosen {
    opacity: .3;
    transform: scale(.8);
  }
}

.screen-as-player-question {
  .player-made-choices {
    position: static;
    overflow: hidden;
    border-radius: var(--borderRadius);
  }
  .player-info {
    position: static;
    overflow: hidden;
    padding: 2.5rem 3rem;
    color: $color-blue-blue-stone;
  }
  .rodal-dialog {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50vw !important;
    min-width: 400px;
    height: auto;
    max-width: 90vw;
    padding: 0;
    color: var(--generalAccentColor);
    background: white;
    border-radius: var(--borderRadius);
  }
}

.team-info {
  &__item--leader {
    font-weight: bold;
    color: white;
    background-color: var(--teamColor);
    border: .1rem solid var(--teamColor);
  }
  &__item--self {
    color: $color-blue-picton-blue;
  }
}


.repository,
.inventory {
  &-item-link {
    display: grid;
    grid-template-columns: auto 4rem;
    & + & {
      margin-top: 1rem;
    }
    background: desaturate(lighten($color-blue-big-stone, 50%), 50%);
    color: white;
    border-radius: calc(var(--borderRadius) / 2);
    cursor: pointer;
    &__name {
      padding: 1rem;
    }
    &__icon {
      padding: 1rem;
      text-align: center;
      color: $color-gray-blue-charcoal;
      svg {
        width: 2rem;
      }
    }
    &__description {
      grid-column: span 2;
      padding: 0 1rem 1rem;
      font-size: 1.5rem;
      font-style: italic;
    }
  }

  &-item-modal {
    &__content audio {
      width: 100%;
    }
    &__description {
      margin-top: .5rem;
      opacity: .5;
      font-size: 1.5rem;
      font-style: italic;
    }
  }
}

.repository-modal-dialog {
  max-width: 1000px;
}
.repository-item-modal__content,
.repository-item-modal__description,
.repository-item-link__description,
.modal-body {
  font-size: medium;
}
.repository-item-link__name {
  font-size: larger;
  font-weight: 700;
}
.repository-item-link__icon {
  color: var(--generalAccentColor);
}

.repository-item-modal__content button,
.repository-item-link {
	padding: 10px;
	line-height: normal;
	color: white;
	background: var(--generalAccentColor);
	border: none;
	border-radius: var(--borderRadius);
}

.inventory-modal-dialog,
.repository-modal-dialog {
  .modal-header {
    font-family: var(--fontFamily);
  }
}


.dropdown-item {
  font-size: large;
  margin: .1rem 0;
  &:hover {
    background-color: whitesmoke;
  }
}
