.login-page {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 10vw;

  display: flex;
  flex-flow: column nowrap;

  &__header {
    flex: 1 1;

    display: flex;
    justify-content: center;
    align-items: flex-start;

    h1 {
      position: relative;

      margin: 0;
      padding-top: 3.8rem;
      line-height: 1em;

      color: transparent;

      &:before {
        content: "";
        position: absolute;
        top: .2rem;
        left: 50%;
        transform: translateX(-50%);
        display: block;
        width: 15rem;
        height: 4rem;
        background: transparent var(--myLogo) center top/15rem auto no-repeat;
      }
    }
  }

  &__form {
    flex: 2 2;
    display: flex;
    justify-content: center;

    margin: 1vw auto;
    padding: 2rem;
    background: white;

    .login-form {
      // display: flex;
      // flex-flow: column nowrap;

      max-width: 28rem;
      width: 100%;

      text-align: center;
      input {
        text-align: center;
      }

      &__fields {
        flex: 1 1;
      }
      &__submit {
        flex: 1 1;

        display: flex;
        justify-content: center;
        align-items: flex-end;
      }
    }
  }
}
