.screen-question {
  padding: 2rem;

  &__content {
    margin: 2rem 0 3rem 0;
    font: font-barlow(2.3rem);
    text-align: center;
  }

  &__choices {
    display: grid;
    width: 100%;
    max-width: 100rem;
    margin: 0 auto;
    grid-gap: 7rem 1.5rem;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 1fr;
  }

  &__choice {
    position: relative;
    padding: 9.5rem 2rem 5rem 2rem;
    text-align: center;
    color: white;
    background: #aaa;
    border-radius: calc(var(--borderRadius) * 2);

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      display: block;
      width: 100%;
      height: 5.5rem;
      background: #bbb;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 5rem auto;
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
    }

    @each $choice-index, $choice-settings in $choice-settings-map {
      &--index--#{$choice-index} {
        background: map-get($choice-settings, "color");
        &:before {
          background-color: map-get($choice-settings, "color2");
          background-image: map-get($choice-settings, "image");
        }
      }
    }

    &__points {
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translate(-50%, -2.5rem);
      width: 100%;
      padding-top: 4.5rem;

      font-size: 2.3rem;
      color: $color-blue-big-stone;

      background-color: transparent;
      background-repeat: no-repeat;
      background-position: center top;
      background-size: 4rem;
      &__stats {
        display: inline-block;
        margin: 0 1rem;
        &:before {
          content: "";
          display: inline-block;
          width: 2rem;
          height: 2rem;
          margin-right: .5rem;
          background-repeat: no-repeat;
          background-position: center bottom;
          background-size: 2rem;
        }
      }
      &__received {
        display: inline-block;
        margin: 0 1rem;
        &:before {
          content: "+";
        }
      }
    }
    &--correct &__points {
      background-image: url(../media/gfx/icon-correct.svg);
      &__stats {
        color: $color-green-christi;
        &:before {
          background-image: url(../media/gfx/icon-correct-person.svg);
        }
      }
    }
    &--not-correct &__points {
      background-image: url(../media/gfx/icon-not-correct.svg);
      &__stats:before {
        background-image: url(../media/gfx/icon-not-correct-person.svg);
      }
    }
  }
  &--choices-count--5 &__choice,
  &--choices-count--6 &__choice {
    padding: 7.5rem 1.5rem 4rem 1.5rem;
  }


  &__countdown {
    position: fixed;
    top: 0;
    right: 0;
    padding: .2rem 1rem;
    color: white;
  }
}
