.leaderboard-table {
  width: 100%;
  max-width: none;
  padding: 10vw;
  overflow: visible;

  &__table {
    width: 100%;
  }

  &__row {
    &__rank {
      padding: .3rem;
    }
  }

  &__spacer-row {
    height: .5rem;
  }

  &__metric-name {
    padding-left: .5rem;
    padding-right: .5rem;
    text-align: right;
    white-space: nowrap;
    &:last-child {
      padding-right: 1.5rem;
    }
  }

  &__row td {
    padding-left: .5rem;
    padding-right: .5rem;
    vertical-align: top;
  }
  &__row td:first-child {
    padding-left: 1.5rem;
  }
  &__row td:last-child {
    padding-right: 1.5rem;
  }

  &__row__rank { width: 0; }
  &__row__nickname {
    word-break: break-word;
    .team-info-container {
      width: 100%;
      .dropdown-toggle {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        padding-top: 0;
        color: inherit;
        text-align: left;
        font-size: inherit;
        @include overflow-ellipsis();
        background: transparent;
        border: none;
        &:focus {
          outline: 0;
          border: none;
          box-shadow: none;
        }
      }
      .show .dropdown {
        position: static;
      }
    }
  }
  &__row__points {
    width: 0;
  }
  &__row__metric {
    width: 0;
  }

  &__row--leading {
    position: relative;
    font-size: larger;
    color: white;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -10;
      pointer-events: none;
      border-radius: calc(var(--borderRadius) * 2);
      background: var(--generalAccentColor);
    }
  }
  &__row--not-leading {
    border-bottom: .05rem solid $color-gray-oslo-gray;
  }
}
