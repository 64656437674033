.leaderboard {
  width: 100%;
  max-width: none;
  padding: 10vw;
  overflow: visible;
  &__rows {
    width: 100%;
    display: block;
    &__row {
      width: 100%;
    }
  }
  &__row {
    display: grid;
    grid-template-columns: 4rem auto;
    &__rank {
      padding: .3rem;
      &:after {
        content: ".";
      }
    }
    &__details {
      display: grid;
      grid-template-columns: 3fr auto;
      padding: .3rem .5rem;
      border: none;
      border-radius: var(--borderRadius);
      &__nickname {
        .team-info-container {
          width: 100%;
          .dropdown-toggle {
            position: relative;
            top: 0;
            left: 0;
            width: 100%;
            padding-top: 0;
            color: inherit;
            text-align: left;
            font-size: inherit;
            @include overflow-ellipsis();
            background: transparent;
            border: none;
            &:focus {
              outline: 0;
              border: none;
              box-shadow: none;
            }
          }
          .show .dropdown {
            position: static;
          }
        }
      }
      &__points {
        padding: .3rem 1rem .3rem 0;
        text-align: right;
        font: font-barlow(1.3em, normal, 1em);
        font-size: inherit;
      }
    }

    &--leading {
      border-radius: calc(var(--borderRadius) * 2);
      margin-bottom: 2rem;
      font-size: larger;
      background: var(--generalAccentColor);
    }
    &--leading &__rank {
      color: white;
      padding-left: 1.5rem;
    }
    &--leading &__details {
      color: white;
      border: none;
      background: transparent;
    }
    &--not-leading {
      border-bottom: .05rem solid $color-gray-oslo-gray;
      border-radius: 0;
    }
  }
}
